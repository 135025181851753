import { graphConfig } from "./authConfig";

/**
 * Attaches a given access token to a MS Graph API call. Returns information about the user
 * @param accessToken 
 */
export async function callMsGraph(accessToken, accessPackageId) {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;
    //console.log('Token'+ accessToken)
    headers.append("Authorization", bearer);

    const options = {
        method: "GET",
        headers: headers
    };

    const requestBody = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': bearer },
        body: JSON.stringify({
            "requestType": "userAdd",
            "assignment": {
                "accessPackageId": accessPackageId
            }
        })
    }  
    
    return fetch(graphConfig.graphAssignmentRequestEndpoint, requestBody)
    .then(response => {console.log(response); return response.json()})
    .catch(error => {console.log(error)})
        //.then(response => {console.log(response);
            //response.setHeader('Strict-Transport-Security', 'max-age=63072000; includeSubDomains').json()})
            //.catch(error => {console.log(error);
                //error.json()})
    ;

    
}
