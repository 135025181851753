import React, { useEffect, useState } from "react";
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal, MsalAuthenticationTemplate } from "@azure/msal-react";
import { InteractionStatus, InteractionType, InteractionRequiredAuthError, AccountInfo } from "@azure/msal-browser";
import { loginRequest } from "./authConfig";
import { PageLayout } from "./components/PageLayout";
import { ProfileData } from "./components/ProfileData";
import { callMsGraph } from "./graph";
import Button from "react-bootstrap/Button";
import "./styles/App.css";
import { graphConfig } from "./authConfig";
import { TailSpin, Bars } from "react-loader-spinner";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";   //Browser Route

/**
 * Renders information about the signed-in user or a button to retrieve data about the user
 */
const ProfileContent = () => {
    const { instance, accounts, inProgress } = useMsal();
    const [graphData, setGraphData] = useState(null);
    const [graphAssignmentData, setgraphAssignmentData] = useState(null);
    const [expireHours, setExpireHours] = useState(null);
    const [loading, setLoading] = useState(true);
    const [deviceType, setDeviceType] = useState(null);           // Added Device Type to check Mac / Mobile
    let id;
    const [hostedDomain, setHostedDomain] = useState(null);       // Added Domain to enable Routing, Fetches value from host name.
    //const _url = window.location.hostname;


    useEffect(() => {
        const _hostname = window.location.hostname
        if (!graphData && inProgress === InteractionStatus.None) {
            // callMsGraph().then(response => {setGraphData(response); })
            // .catch((e) => {
            //     console.log(e);
            //     if (e instanceof InteractionRequiredAuthError) {
            //         instance.acquireTokenRedirect({
            //             ...loginRequest,
            //             account: instance.getActiveAccount()
            //         });
            //     }
            // });
            //RequestRegistration();
            console.log('_hostname', _hostname);
            setLoading(false);
            //Logic to redirect based on Host Name
            if (_hostname != undefined) {
                setHostedDomain(_hostname);
                if (_hostname.includes("mobileenroll")) {
                    RequestRegistration('mobile');
                }
                else if (_hostname.includes("macenroll")) {
                    RequestRegistration('mac');
                }
            }
        }
    }, [inProgress, graphData, instance]);
    // Device Type value is passed on here. 
    function RequestRegistration(_deviceType) {
        instance.acquireTokenSilent({
            ...loginRequest,
            account: accounts[0]
        }).then((response) => {
            if (response.accessToken !== null && response.accessToken !== undefined) {
                setLoading(true);
                setDeviceType(_deviceType)
                let devicePacId = '285acbbc-7cbe-49b6-bc6c-3e938922526a';     // This defaults to Mobile 
                let assignEnsignEndpoint = graphConfig.graphAssignmentListEndpoint;
                if (_deviceType == 'mac') {
                    devicePacId = '6878eb95-3815-48df-b7dd-714695b03d06';
                    assignEnsignEndpoint = graphConfig.graphAssignmentListEndpointMac
                }
                callMsGraph(response.accessToken, devicePacId).then(response1 => {
                    setgraphAssignmentData(response1);
                    if (!response1.hasOwnProperty("status") && response1.error.code === "InvalidRequestExistingGrant") {
                        id = (response1.error.message).split(': ')[1];
                        // const expirationInfo = fetch(graphConfig.graphAssignmentEndpoint+id, {method: "GET", headers: { 'Authorization': `Bearer ` + response.accessToken }})
                        // .then((response2) => { return response2.json(); })
                        // .catch(error => {setgraphAssignmentData("error"); setExpireHours("0")})
                        const listOfAssignmentsForTheSignedInUser = fetch(assignEnsignEndpoint, { method: "GET", headers: { 'Authorization': `Bearer ` + response.accessToken } })
                            .then((response2) => { return response2.json(); })
                            .catch(error => {
                                console.log(error);
                                setgraphAssignmentData("error"); setExpireHours("0")
                            })

                        const getList = async () => {
                            const z = await listOfAssignmentsForTheSignedInUser;
                            const list = z.value;
                            const activeAssignment = list.filter(function (item) {
                                return item.id == id;
                            });
                            const expirationDate = activeAssignment[0].schedule.expiration.endDateTime;
                            var date1 = new Date(expirationDate);
                            var date2 = new Date();
                            const remainingTime = Math.round(((date1.getTime() - date2.getTime()) / (1000 * 3600)) * 100) / 100;
                            setExpireHours(remainingTime);
                        };
                        getList();
                        setLoading(false);
                    }
                    else {
                        //id = response1.id;callMsGraph
                        setExpireHours("0");
                        setgraphAssignmentData(response1);
                        setLoading(false);
                    }
                })
                    .catch(error => {
                        console.log(error);
                        setgraphAssignmentData("error"); setExpireHours("0")
                    });

            }
            else {
                setgraphAssignmentData("error");
                setExpireHours("0");
            }
        }).catch((error) => {
            console.log(error);
            setgraphAssignmentData("error"); setExpireHours("0")
        }).finally(setLoading(false));
    }
    // Logic to Display Navigation buttons for MAC & Mobile. 
    return (
        <>
            {loading ? (
                <Bars color="#0071c5" height="50" width="50" radius="10" wrapperStyle={{ alignItems: "center", justifyContent: "center", fontWeight: "bold" }} />
            ) : (
                <p></p>
            )}

            {(expireHours == null && hostedDomain != null && hostedDomain.includes("mobileenroll")) ? (
                <p></p>
            ) : ((expireHours == null && hostedDomain != null && hostedDomain.includes("macenroll")) ? (
                <p></p>
            ) : (
                (expireHours == null) ? (
                    <p>
                        <Button variant="primary" onClick={() => RequestRegistration('mobile')}>Click Here to Request Access to enroll Android/iOS Devices</Button>
                        <br /><br />
                        <Button variant="primary" onClick={() => RequestRegistration('mac')}>Click Here to Request Access to enroll mac Devices</Button>
                    </p>
                ) : (
                    <p></p>
                ))
            )
            }
            {graphAssignmentData && expireHours && !loading ? (
                <ProfileData graphAssignmentData={graphAssignmentData} expireHours={expireHours} loading={loading} deviceType={deviceType} />
            ) : (
                <p></p>
            )}
        </>
    );
};

const authRequest = {
    ...loginRequest
};

/**
 * If a user is authenticated the ProfileContent component above is rendered. Otherwise a message indicating a user is not authenticated is rendered.
 */
const MainContent = () => {
    return (
        <div className="App">
            <MsalAuthenticationTemplate authenticationRequest={authRequest} interactionType={InteractionType.Redirect} >
                <ProfileContent />
            </MsalAuthenticationTemplate>

            <UnauthenticatedTemplate>
                <h5 className="card-title">Signing In....</h5>
            </UnauthenticatedTemplate>
        </div>
    );
};
// Logic to Change the HTML Display header based on Device Type. 
export default function App() {
    const [deviceType, setDeviceType] = useState(null);
    useEffect(() => {
        const _hostname = window.location.hostname
        console.log('hostname',_hostname);
        if (_hostname != undefined) {
            if (_hostname.includes("mobileenroll")) {
                setDeviceType('mobile');
            }
            else if (_hostname.includes("macenroll")) {
                setDeviceType('mac');
            }
        }
    });
    return (
        <PageLayout deviceType={deviceType}>
            <MainContent />
        </PageLayout>
    );
}
